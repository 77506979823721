<template>
  <div>
    <tabla-generica 
    title = "Prioridades"
    :rows = 'prioridades' 
    :titles = 'titulos' 
    @newRecord = 'nuevo'
    @editRecord = 'editar'
    @rowSelect = 'seleccionar'
    ></tabla-generica>
    
    <!-- Modal Component -->
    <b-modal 
    id="modal1" 
    title="Tabla de Prioridades"
    cancel-title = " No "
    ok-title=" Si "
    @ok="borrar">
      <p class="my-4">Desea eliminar la prioridad?</p>
    </b-modal>
  </div>
</template>

<script>
  import axios from 'axios';
  import TablaGenerica from '@/components/tools/TablaGenerica2.vue';

  export default {
    mounted: function() {
        this.getTablas()
      },
    components: {
      TablaGenerica
    },
    data() {
      return {
        rowId: undefined,
        prioridades: [],
        titulos: {
          id: {
            label: 'ID'            
          },
          nombre: {
            label: 'NOMBRE'
          },
          prioridad: {
            label: 'PRIORIDAD'
          }
          /*,
          'user.text': {
            label: 'USUARIO'
          }*/
        },      
        textoBuscar: 'sdfsdfsdfsdfsdfsdfsdfsd'
      }
    },
    methods: {

      getTablas() {        
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get('token');
        axios.get(process.env.VUE_APP_ROOT_API + "api/secure/prioridades")
        .then((response) => {                
          this.prioridades = response.data;
            //this.$router.replace({ path: '/portal/proveedores' });                                                                 
        }, (error) => {
            //console.log("Error: " + JSON.stringify( error.data) );
        })
      },      
      seleccionar(row){
        this.rowId = row.id;          
      },
      nuevo: function(){
        this.$router.push({ name: 'Prioridad', params: { id: 0, prioridad: {id: null, nombre: null, prioridad: null}  }})            
      },
      editar: function(){
        if (!(this.rowId === undefined)){     
          this.$router.push({ name: 'Prioridad', params: { id: this.rowId, prioridad: this.prioridades.find(row => row.id === this.rowId)}})            
        }
      },        
      borrar: function() {
        if (!(this.rowId === undefined)){                 
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get('token');
          axios.delete(process.env.VUE_APP_ROOT_API + "api/secure/prioridades/" + this.rowId)
          .then((response) => {                      
            this.getZonas();
          }, (error) => {          
          })
        }
      },
    }
  }
</script>

<style>  
</style>